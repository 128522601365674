<template>
  <div class="main-styles">
    <p>
      手球<br />一般规则<br />
      1、所有手球投注一律以六十分钟的完场球赛赛果为准，除非另有特别说明，伤停补时计算在内。<br />2、如果比赛提前开赛，只有在开赛之前投注的注单被视为有效投注。在开赛之后投注的注单将被视为无效投注，滚球投注类型不包括在内。<br /><br />投注类型<br />1
      X 2<br />1、预测哪支球队将赢得比赛，盘口将提供两支球队及平局作为投注选项。
    </p>
    <p><br />让球盘<br />1、预测哪支球队将获得让球比赛/赛节的胜利。</p>
    <p><br />滚球让球盘<br />1、预测哪支球队将获得让球比赛/赛节的胜利。</p>
    <p>
      2、滚球让球盘的输赢是以比赛最终比分减去投注当时的比赛比分，在计算让球分数下根据比分差来评判输赢。
    </p>
    <p><br />大/小盘（球）<br />1、预测赛事总进球数将大于或小于盘口分数。</p>
    <p>
      <br />滚球大/小盘（球）<br />1、预测赛事总进球数将大于或小于盘口分数。
    </p>
    <p>
      2、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，大小球投注的注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      3、滚球大小球比分以0-0开始计算，派彩是根据最终的比分为准，投注当时的比分不计算在内。
    </p>
    <p><br />单/双<br />1、预测赛事总进球数是单数或双数。</p>
  </div>
</template>
